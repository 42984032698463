.awssld--foldOutAnimation {
  --fold-out-perspective: 2000px;
  --fold-out-angle: 90deg; }
  .awssld--foldOutAnimation .awssld__container {
    -webkit-perspective: var(--fold-out-perspective);
            perspective: var(--fold-out-perspective); }
  .awssld--foldOutAnimation .awssld--exit.awssld--moveLeft, .awssld--foldOutAnimation .awssld--exit.awssld--moveRight {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
  .awssld--foldOutAnimation .awssld--exit.awssld--moveLeft {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-animation: foldLeft var(--slider-transition-duration) both var(--transition-bezier);
            animation: foldLeft var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--foldOutAnimation .awssld--exit.awssld--moveRight {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-animation: foldRight var(--slider-transition-duration) both var(--transition-bezier);
            animation: foldRight var(--slider-transition-duration) both var(--transition-bezier); }

@-webkit-keyframes foldLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
            transform: translate3d(0, 0, 0) rotateY(0deg); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1));
            transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1)); } }

@keyframes foldLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
            transform: translate3d(0, 0, 0) rotateY(0deg); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1));
            transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1)); } }

@-webkit-keyframes foldRight {
  from {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
            transform: translate3d(0, 0, 0) rotateY(0deg); }
  to {
    -webkit-transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle));
            transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle)); } }

@keyframes foldRight {
  from {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
            transform: translate3d(0, 0, 0) rotateY(0deg); }
  to {
    -webkit-transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle));
            transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle)); } }

