// Facebook
$button-facebook-color: #4868ad;
$button-facebook-color-dark: darken($button-facebook-color, 15%);

// Messenger
$button-messenger-color: #3186f6;
$button-messenger-color-dark: darken($button-messenger-color, 15%);

// Twitter
$button-twitter-color: #00aced;
$button-twitter-color-dark: darken($button-twitter-color, 15%);

// Mail
$button-mail-color: #cfcfcf;
$button-mail-color-dark: darken($button-mail-color, 20%);

// Linkedin
$button-linkedin-color: #0077b5;
$button-linkedin-color-dark: darken($button-linkedin-color, 15%);

// Whatsapp
$button-whatsapp-color: #25d366;
$button-whatsapp-color-dark: darken($button-whatsapp-color, 15%);

// Github
$button-github-color: #25292e;
$button-github-color-dark: darken($button-github-color, 15%);

// Reddit
$button-reddit-color: #fc461e;
$button-reddit-color-dark: darken($button-reddit-color, 15%);

// Pinterest
$button-pinterest-color: #bd091c;
$button-pinterest-color-dark: darken($button-pinterest-color, 15%);

// GPlus
$button-gplus-color: #ce5b4e;
$button-gplus-color-dark: darken($button-gplus-color, 15%);

// Youtube
$button-youtube-color: #cc181e;
$button-youtube-color-dark: darken($button-youtube-color, 15%);

// Instagram
$button-instagram-radial-color: radial-gradient(circle at 25% 110%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$button-instagram-radial-color-dark: radial-gradient(circle at 25% 110%, #b9a800 0%, #938500 5%, #b10f00 45%,#8c0061 60%,#002ca9 90%);
