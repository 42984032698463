$button-default-height: 44px;
$button-default-font-size: 14px;
$button-default-line-height: 24px;
$button-default-border-radius: 2px;
$button-default-placeholder-width: 120px;
$button-default-placeholder-font-color: rgba(0, 0, 0, 0.1);

$button-font-family: inherit;
$button-font-color: #ffffff;
$button-font-weight: 600;
$button-font-style: normal;
$button-hover-darken-opacity: 0.1;
$button-letter-spacing: 0px;
$button-horizontal-padding: 16px;

$button-raise-level: 2px;
$button-hover-pressure: 1;

$loading-transition-speed: 6s;
$loading-transition-timing: ease-out;
$transform-speed: 0.15s;

$button-icon-width: 44px;
$button-icon-height: $button-default-height;
$button-small-width: 88px;
$button-small-height: $button-default-height;
$button-medium-width: 136px;
$button-medium-height: $button-default-height;
$button-large-width: 192px;
$button-large-height: $button-default-height;
